<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Convention collective" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de la convention collective</div>
              </v-row>
            </v-card-title>

             <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom complet -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    nom complet
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le lien -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    lien
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le lien"
                    dense
                    v-model="link"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'identifiant national -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    IDCC national
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'IDCC"
                    dense
                    v-model="idcc"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le numéro -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    numéro
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le numéro"
                    dense
                    v-model="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'échelon -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    échelon
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caGrade"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la catégorie -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    catégorie
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caCategory"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le niveau -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    niveau
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caLevel"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le coefficient -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    coefficient
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caCoefficient"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- les points -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    points
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="caPoints"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>


            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { CollectiveAgreementService } from "@/service/user/collective_agreement_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditCollectiveAgreement",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      collectiveAgreementsService: null,

      /** l'identifiant à éditer */
      collectiveAgreementId: null,

      /**La donnée originale */
      source: null,

      /**le nom */
      name: null,
      /** le nom complet */
      label: null,
      /** le lien */
      link: null,
      /** L'idcc (identifiant national) */
      idcc: null,
      /** le numéro */
      number: null,
      /** l'échelon */
      caGrade: null,
      /** la catégorie */
      caCategory: null,
      /** le niveau */
      caLevel: null,
      /** Le coefficient */
      caCoefficient: null,
      /** les points */
      caPoints: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let item = await this.collectiveAgreementsService.getById(
          this.collectiveAgreementId
        );
        this.source = JSON.parse(JSON.stringify(item));

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.name = this.source.name;
      this.label = this.source.label;
      this.link = this.source.link;
      this.idcc = this.source.idcc;
      this.number = this.source.number;
      this.caGrade = this.source.caGrade;
      this.caCategory = this.source.caCategory;
      this.caLevel = this.source.caLevel;
      this.caCoefficient = this.source.caCoefficient;
      this.caPoints = this.source.caPoints;
    },

    async save() {
      // enregistrement de l'acronyme
      if (this.collectiveAgreementHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.name = this.name;
          entity.label = this.label;
          entity.link = this.link;
          entity.idcc = this.idcc;
          entity.number = this.number;
          entity.caGrade = this.caGrade;
          entity.caCategory = this.caCategory;
          entity.caLevel = this.caLevel;
          entity.caCoefficient = this.caCoefficient;
          entity.caPoints = this.caPoints;

          await this.collectiveAgreementsService.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);

        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la convention collective : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },
  },
  computed: {
    completed() {
      if (!this.name) return false;
      if (!this.label) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.collectiveAgreementHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'acronyme est modifié */
    collectiveAgreementHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.name, this.name)) {
        changed = true;
      }
      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      if (!areTheSame(this.source.link, this.link)) {
        changed = true;
      }
      if (this.source.idcc != this.idcc) {
        changed = true;
      }
      if (this.source.number != this.number) {
        changed = true;
      }
      if (this.source.caGrade != this.caGrade) {
        changed = true;
      }
      if (this.source.caCategory != this.caCategory) {
        changed = true;
      }
      if (this.source.caLevel != this.caLevel) {
        changed = true;
      }
      if (this.source.caCoefficient != this.caCoefficient) {
        changed = true;
      }
      if (this.source.caPoints != this.caPoints) {
        changed = true;
      }
      
      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.collectiveAgreementsService = new CollectiveAgreementService(this.$api.getCollectiveAgreementApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.collectiveAgreementId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>